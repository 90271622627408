var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "md",
      "centered": "",
      "hide-footer": "",
      "content-class": "chat"
    },
    on: {
      "hidden": _vm.onCloseModal
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" Tienda Inactiva ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showAlert,
      callback: function callback($$v) {
        _vm.showAlert = $$v;
      },
      expression: "showAlert"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "text-msg"
  }, [_vm._v(" Tu tienda se encuentra inactiva, los clientes no podrán encontrarte en la APP ni hacer pedidos. ")]), _c('b-button', {
    staticClass: "mb-75",
    attrs: {
      "block": "",
      "variant": 'outline-primary'
    },
    on: {
      "click": _vm.showMsgEstado
    }
  }, [_c('div', [_vm._v(" Activar Tienda ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }