<template>
  <b-modal size="md" v-model="showAlert" centered hide-footer content-class="chat" @hidden="onCloseModal">
    <template #modal-title>
      Tienda Inactiva
    </template>

    <b-col>
      <div class="text-msg">
        Tu tienda se encuentra inactiva, los clientes no podrán encontrarte en
        la APP ni hacer pedidos.
      </div>
      <b-button class="mb-75" block :variant="'outline-primary'" @click="showMsgEstado">
        <div>
          Activar Tienda
        </div>
      </b-button>
    </b-col>
  </b-modal>
</template>

<script>
import {
  getUserID,
  getAuthToken,
  getDefaultStore,
  getSpecialViewsRol,
} from "@/auth/utils";
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    ToastificationContent,
  },
  props: {
    activateCallback: {
      type: Function,
      default: () => { },
    },
    useDefaultStore: {
      type: Boolean,
      default: true,
    },
    selectedStore: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    selectedStore: function (newVal) {
      if (newVal !== null) {
        this.storeID = newVal;
        this.$nextTick(() => {
          this.fetchStoreData();
        });
      }
    },
  },
  data() {
    return {
      error: null,
      storeID: null,
      loading: false,
      storeData: null,
      showAlert: false,
      showErrorModal: false,
    };
  },
  created() {
    this.storeID = this.selectedStore;
  },
  mounted() {
    this.fetchStoreData();
  },
  methods: {
    onCloseModal() {
      this.showAlert = false;
    },
    getStoreData() {
      this.loading = true;
      return this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/EmpresaAsociadaTiendaById",
          body: JSON.stringify({
            IDEmpresa: 2,
            tokenSesion: getAuthToken(),
            IDEmpresaAsociada: this.useDefaultStore ? getDefaultStore() : this.storeID,
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.storeData = res.data.lisEmpresaAsociada[0];
            this.$emit("on-status-store", res.data.lisEmpresaAsociada[0]);
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    async fetchStoreData() {
      if (getSpecialViewsRol("ActivateShop")) {
        // Consultar si la tienda está activa
        if (this.useDefaultStore || this.storeID !== 0) {
          await this.getStoreData();
          if (this.storeData && this.storeData.activo == 0) {
            this.showAlert = true;
          }
        }
      }
    },
    showMsgEstado() {
      this.showAlert = false;
      this.$bvModal
        .msgBoxConfirm("Esta seguro que desea Activar la Tienda?", {
          size: "sm",
          title: "Activar Tienda",
          okTitle: "Activar",
          centered: true,
          okVariant: "primary",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.loadingActivate = true;
            this.$http
              .post(this.$store.state.app.middlewareURL, {
                path: "/Administracion/AdminEmpresaAsociada",
                body: JSON.stringify({
                  opt: "ADT",
                  empresa: 2,
                  usuario: `${getUserID()}`,
                  idAsociada: this.storeData.idEmpresaAsociado,
                  tokenSesion: getAuthToken(),
                }),
              })
              .then((res) => {
                if (res.data.bOk) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Éxito",
                      icon: "CheckIcon",
                      text: "Se cambio correctamente el estado de la tienda.",
                      variant: "success",
                    },
                  });
                  this.storeData.activo = 1;
                  this.activateCallback(this.storeData);
                } else {
                  throw new Error(res.data.mensaje);
                }
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "AlertTriangleIcon",
                    text: `Ocurrió un error al cambiar el estado de la tienda (${e.message}).`,
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.showAlert = false;
                this.loadingActivate = false;
              });
          }
        });
    },
  },
};
</script>
<style lang="css" scoped>
.text-msg {
  text-align: justify;
  padding: 4px;
  margin: 2px;
}
</style>
