var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.error.length > 0
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Ocurrió un error al obtener las ordenes " + _vm._s(_vm.error) + " ")])]), _vm.storeSelected !== _vm.allStoresKey && _vm.storeSelected !== 0 ? _c('modal-activate-shop', {
    attrs: {
      "use-default-store": false,
      "selected-store": _vm.storeSelected,
      "activate-callback": _vm.onActivatedStore
    },
    on: {
      "on-status-store": _vm.onStatusStore
    }
  }) : _vm._e(), _vm.error.length === 0 ? _c('b-card', {
    attrs: {
      "title": "Ordenes"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [!_vm.isPicker && !_vm.isSupervisor && !_vm.isCajero ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Store",
      "label-for": "v-local"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-local",
      "label": "nombre",
      "reduce": function reduce(store) {
        return store.id;
      },
      "options": _vm.stores,
      "loading": _vm.loadingStores,
      "clearable": false,
      "placeholder": "Seleccione un Store",
      "filterable": true
    },
    on: {
      "option:selected": _vm.onChangeFilters
    },
    model: {
      value: _vm.storeSelected,
      callback: function callback($$v) {
        _vm.storeSelected = $$v;
      },
      expression: "storeSelected"
    }
  })], 1)], 1) : _vm._e(), _vm.isSuperUser || _vm.isPicker || _vm.isSupervisor ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bodega",
      "label-for": "v-bodega"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "v-bodega",
      "label": "nombre",
      "reduce": function reduce(bodega) {
        return bodega.id;
      },
      "options": _vm.bodegas,
      "loading": _vm.loadingBodegas,
      "clearable": true,
      "placeholder": "Seleccione una bodega",
      "filterable": true
    },
    on: {
      "option:selected": _vm.onChangeFilters
    },
    model: {
      value: _vm.bodegaSelected,
      callback: function callback($$v) {
        _vm.bodegaSelected = $$v;
      },
      expression: "bodegaSelected"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cliente",
      "label-for": "v-cliente"
    }
  }, [_c('v-select', {
    ref: "clienteSelect",
    attrs: {
      "id": "v-cliente",
      "label": "nombre",
      "reduce": function reduce(cliente) {
        return cliente.id;
      },
      "options": _vm.clientes,
      "loading": _vm.loadingClientes,
      "clearable": true,
      "placeholder": "Seleccione un cliente",
      "filterable": false
    },
    on: {
      "option:selected": _vm.onChangeFilters
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(_vm._s(option.identificacion))]), _c('div', [_vm._v(_vm._s(option.nombre))]), _c('div', [_vm._v(_vm._s(option.correo))])];
      }
    }], null, false, 4007348613),
    model: {
      value: _vm.clienteSelected,
      callback: function callback($$v) {
        _vm.clienteSelected = $$v;
      },
      expression: "clienteSelected"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Número de orden",
      "label-for": "v-buscar"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "id": "v-buscar",
      "placeholder": "Número de orden",
      "type": "number",
      "onkeydown": "javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight','ControlLeft','KeyV'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
    },
    on: {
      "input": _vm.onSearch
    }
  })], 1)], 1), _vm.showActivateButton ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.showMsgEstado
    }
  }, [_vm.loadingActivate ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v(" Activar Tienda ")])], 1)], 1) : _vm._e()], 1), _c('vue-good-table', {
    attrs: {
      "rows": _vm.rows,
      "columns": _vm.columns,
      "isLoading": _vm.loading,
      "sort-options": {
        enabled: false
      },
      "search-options": {
        enabled: true,
        externalQuery: _vm.searchTerm
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "totalRows": _vm.totalRecords
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.loading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.loading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'fechacreacion' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.formattedDate(props.row.fechacreacion)))]), _c('small', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.formattedHour(props.row.fechacreacion)))])]) : props.column.field === 'origen' ? _c('span', [props.row.origen == 1 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/se1.png'),
            "width": "25"
          }
        }) : _vm._e(), props.row.origen == 2 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/se2.png'),
            "width": "25"
          }
        }) : _vm._e(), props.row.origen == 3 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/rappi.png'),
            "width": "25"
          }
        }) : _vm._e(), props.row.origen == 4 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/lite.png'),
            "width": "25"
          }
        }) : _vm._e()], 1) : props.column.field === 'idorden' ? _c('span', {
          staticClass: "mx-auto"
        }, [_c('a', {
          staticClass: "w-100",
          attrs: {
            "href": "orden/".concat(props.row.idorden),
            "target": "_blank"
          }
        }, [_c('span', {
          staticClass: "mr-25 align-middle"
        }, [_vm._v(" " + _vm._s(props.row.idorden) + " ")])]), _c('a', {
          staticClass: "w-100",
          attrs: {
            "href": "orden/".concat(props.row.idorden),
            "target": "_blank"
          }
        }, [_c('span', {
          staticClass: "mr-25 align-middle"
        }, [_vm._v(" " + _vm._s(props.row.numero_orden_se1) + " ")])])]) : props.column.field === 'nombrecliente' ? _c('span', [props.row.is_user_subscribed ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/infinite.png'),
            "height": "28"
          }
        }) : _vm._e(), _c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props.row.tipocliente.replace("C", "")) + " ")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.nombrecliente) + " ")])], 1) : props.column.field === 'samplings' ? _c('span', [props.row.samplings && props.row.samplings.length != 0 ? _c('div', [_c('div', {
          staticStyle: {
            "display": "flex",
            "justify-content": "center"
          }
        }, [_c('b-avatar', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "rounded": "",
            "variant": "light-secondary",
            "size": "25"
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "color": "#04a82c"
          },
          attrs: {
            "icon": "GiftIcon",
            "size": "18"
          },
          on: {
            "click": function click($event) {
              return _vm.onSamplingsClick(props.row.samplings, props.row.idorden);
            }
          }
        })], 1)], 1), _c('b-button', {
          staticStyle: {
            "font-size": "11.05px",
            "padding": "0.3rem 0.5rem",
            "color": "#04a82c"
          },
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.onSamplingsClick(props.row.samplings, props.row.idorden);
            }
          }
        }, [_vm._v("Ver Samplings")])], 1) : _c('div', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" -- ")])])]) : props.column.field === 'picker' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.picker === "" ? "--" : props.row.picker) + " ")])]) : props.column.field === 'estadoorden' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant(props.row.estadoorden)
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText(props.row.estadoorden)) + " ")]), props.row.ordenIncompleta ? _c('div', [_c('feather-icon', {
          staticClass: "ml-25",
          attrs: {
            "icon": "AlertTriangleIcon",
            "size": "20",
            "id": "popover-".concat(props.row.idorden)
          }
        }), _c('b-popover', {
          attrs: {
            "variant": "warning",
            "target": "popover-".concat(props.row.idorden),
            "placement": "bottom",
            "title": "Productos incompletos",
            "triggers": "hover"
          }
        }, _vm._l(props.row.ordenIncompletaMensaje.split(','), function (item, index) {
          return _c('b-col', {
            key: index,
            staticClass: "p-0"
          }, [_vm._v(" • " + _vm._s(item) + " ")]);
        }), 1)], 1) : _vm._e()], 1) : props.column.field === 'tipoentrega' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.entregaVariant(props.row.tipoentrega)
          }
        }, [_vm._v(" " + _vm._s(_vm.entregaText(props.row.tipoentrega)) + " ")]), props.row.tipoentrega === 'PG' ? _c('div', [_vm._v(" " + _vm._s(_vm.formattedDate(props.row.fechaprogramada)) + " " + _vm._s(_vm.formattedHour(props.row.fechaprogramada)) + " ")]) : _vm._e()], 1) : props.column.field === 'motorizado' ? _c('span', [_vm._v(" " + _vm._s(props.row.motorizado === "" ? "--" : props.row.motorizado) + " ")]) : props.column.field === 'lineadenegocio' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props.row.lineadenegocio) + " ")]), _c('br'), _vm._v(" " + _vm._s(props.row.lineadenegocio === "MARKET" ? props.row.bodega ? props.row.bodega : "" : props.row.empresaasociada) + " ")], 1) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Cantidad de ordenes ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        })], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "align": "right",
            "value": 1,
            "per-page": _vm.pageLength,
            "prev-class": "prev-item",
            "next-class": "next-item",
            "last-number": "",
            "total-rows": props.total,
            "first-number": ""
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }], null, false, 1098294732)
  }, [_c('template', {
    slot: "emptystate"
  }, [_vm._v("No hay ordenes para mostrar")])], 2), _c('b-modal', {
    attrs: {
      "title": "Error",
      "ok-only": "",
      "centered": "",
      "ok-title": "Accept",
      "ok-variant": "danger",
      "modal-class": "modal-danger"
    },
    model: {
      value: _vm.showErrorModal,
      callback: function callback($$v) {
        _vm.showErrorModal = $$v;
      },
      expression: "showErrorModal"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1), _c('b-modal', {
    attrs: {
      "title": 'Samplings de la orden #' + _vm.idOrden,
      "ok-only": "",
      "centered": "",
      "ok-title": "Entendido"
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('vue-good-table', {
    attrs: {
      "rows": _vm.samplings,
      "columns": _vm.columnsSamplings,
      "sort-options": {
        enabled: false
      },
      "search-options": {
        enabled: false
      },
      "pagination-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'nombre' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.nombre === "" ? "--" : props.row.nombre) + " ")])]) : props.column.field === 'descripcion' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.descripcion === "" ? "--" : props.row.descripcion) + " ")])]) : props.column.field === 'codigo' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.codigo === "" ? "--" : props.row.codigo) + " ")])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }], null, false, 3712548007)
  }, [_c('template', {
    slot: "emptystate"
  }, [_vm._v("No hay samplings para mostrar")])], 2)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }