<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener las ordenes {{ error }}
      </div>
    </b-alert>

    <modal-activate-shop v-if="storeSelected !== allStoresKey && storeSelected !== 0" @on-status-store="onStatusStore"
      :use-default-store="false" :selected-store="storeSelected" :activate-callback="onActivatedStore" />

    <b-card title="Ordenes" v-if="error.length === 0">
      <b-row class="mb-2">
        <b-col md="4" v-if="!isPicker && !isSupervisor && !isCajero">
          <b-form-group label="Store" label-for="v-local">
            <v-select id="v-local" label="nombre" v-model="storeSelected" :reduce="(store) => store.id"
              :options="stores" :loading="loadingStores" :clearable="false" placeholder="Seleccione un Store"
              :filterable="true" @option:selected="onChangeFilters" />
          </b-form-group>
        </b-col>
        <b-col md="4" v-if="isSuperUser || isPicker || isSupervisor">
          <b-form-group label="Bodega" label-for="v-bodega">
            <v-select id="v-bodega" label="nombre" v-model="bodegaSelected" :reduce="(bodega) => bodega.id"
              :options="bodegas" :loading="loadingBodegas" :clearable="true" placeholder="Seleccione una bodega"
              :filterable="true" @option:selected="onChangeFilters" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Cliente" label-for="v-cliente">
            <v-select ref="clienteSelect" id="v-cliente" label="nombre" v-model="clienteSelected"
              :reduce="(cliente) => cliente.id" :options="clientes" :loading="loadingClientes" :clearable="true"
              placeholder="Seleccione un cliente" :filterable="false" @option:selected="onChangeFilters">
              <template #option="option">
                <b-badge variant="light-success">{{
                  option.identificacion
                }}</b-badge>
                <div>{{ option.nombre }}</div>
                <div>{{ option.correo }}</div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Número de orden" label-for="v-buscar">
            <b-form-input id="v-buscar" class="d-inline-block" @input="onSearch" placeholder="Número de orden"
              type="number"
              onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight','ControlLeft','KeyV'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" />
          </b-form-group>
        </b-col>

        <b-col md="4" v-if="showActivateButton">
          <b-button block variant="primary" @click="showMsgEstado">
            <b-spinner v-if="loadingActivate" small label="Loading..." />
            <div v-else>
              Activar Tienda
            </div>
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table :rows="rows" :columns="columns" :isLoading.sync="loading" :sort-options="{
        enabled: false,
      }" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords">
        <template slot="emptystate">No hay ordenes para mostrar</template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: Fecha -->
          <span v-if="props.column.field === 'fechacreacion'">
            <p class="mb-0">{{ formattedDate(props.row.fechacreacion) }}</p>
            <small class="mb-0">{{ formattedHour(props.row.fechacreacion) }}</small>
          </span>
          <!-- Column: Origen -->
          <span v-else-if="props.column.field === 'origen'">
            <b-img v-if="props.row.origen == 1" :src="require('@/assets/images/misc/se1.png')" width="25" />
            <b-img v-if="props.row.origen == 2" :src="require('@/assets/images/misc/se2.png')" width="25" />
            <b-img v-if="props.row.origen == 3" :src="require('@/assets/images/misc/rappi.png')" width="25" />
            <b-img v-if="props.row.origen == 4" :src="require('@/assets/images/misc/lite.png')" width="25" />
          </span>
          <!-- Column: Numero -->
          <span v-else-if="props.column.field === 'idorden'" class="mx-auto">
            <a class="w-100" :href="`orden/${props.row.idorden}`" target="_blank">
              <span class="mr-25 align-middle">
                {{ props.row.idorden }}
              </span>
            </a>
            <a class="w-100" :href="`orden/${props.row.idorden}`" target="_blank">
              <span class="mr-25 align-middle">
                {{ props.row.numero_orden_se1 }}
              </span>
            </a>
          </span>
          <!-- Column: Cliente -->
          <span v-else-if="props.column.field === 'nombrecliente'">
            <b-img v-if="props.row.is_user_subscribed" :src="require('@/assets/images/misc/infinite.png')"
              height="28" />
            <b-badge variant="light-success">
              {{ props.row.tipocliente.replace("C", "") }}
            </b-badge>
            <p class="mb-0">
              {{ props.row.nombrecliente }}
            </p>
          </span>
          <!-- Column: Samplings -->
          <span v-else-if="props.column.field === 'samplings'">
            <div v-if="props.row.samplings && props.row.samplings.length != 0">
              <div style="display: flex; justify-content: center;">
                <b-avatar rounded variant="light-secondary" size="25" style="cursor: pointer;">
                  <feather-icon icon="GiftIcon" size="18" style="color: #04a82c;"
                    @click="onSamplingsClick(props.row.samplings, props.row.idorden)" />
                </b-avatar>
              </div>
              <b-button variant="success" style="font-size: 11.05px; padding: 0.3rem 0.5rem; color: #04a82c;"
                @click="onSamplingsClick(props.row.samplings, props.row.idorden)">Ver Samplings</b-button>
            </div>
            <div v-else>
              <p class="mb-0">
                --
              </p>
            </div>
          </span>
          <!-- Column: Picking -->
          <span v-else-if="props.column.field === 'picker'">
            <p class="mb-0">
              {{ props.row.picker === "" ? "--" : props.row.picker }}
            </p>
          </span>
          <!-- Column: Estado -->
          <span v-else-if="props.column.field === 'estadoorden'">
            <b-badge :variant="statusVariant(props.row.estadoorden)">
              {{ statusText(props.row.estadoorden) }}
            </b-badge>
            <div v-if="props.row.ordenIncompleta">
              <feather-icon icon="AlertTriangleIcon" size="20" :id="`popover-${props.row.idorden}`" class="ml-25" />
              <b-popover variant="warning" :target="`popover-${props.row.idorden}`" placement="bottom"
                title="Productos incompletos" triggers="hover">
                <b-col v-for="(item, index) in props.row.ordenIncompletaMensaje.split(',')" :key="index" class="p-0">
                  • {{ item }}
                </b-col>
              </b-popover>
            </div>
          </span>
          <!-- Column: Entrega -->
          <span v-else-if="props.column.field === 'tipoentrega'">
            <b-badge :variant="entregaVariant(props.row.tipoentrega)">
              {{ entregaText(props.row.tipoentrega) }}
            </b-badge>
            <div v-if="props.row.tipoentrega === 'PG'">
              {{ formattedDate(props.row.fechaprogramada) }}
              {{ formattedHour(props.row.fechaprogramada) }}
            </div>
          </span>
          <!-- Column: Motorizado -->
          <span v-else-if="props.column.field === 'motorizado'">
            {{ props.row.motorizado === "" ? "--" : props.row.motorizado }}
          </span>
          <!-- Column: Tienda -->
          <span v-else-if="props.column.field === 'lineadenegocio'">
            <b-badge variant="light-success">
              {{ props.row.lineadenegocio }}
            </b-badge>
            <br />
            {{
              props.row.lineadenegocio === "MARKET"
                ? props.row.bodega
                  ? props.row.bodega
                  : ""
                : props.row.empresaasociada
            }}
          </span>
          <!-- Column: Otras Columnas -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Cantidad de ordenes </span>
              <b-form-select class="mx-1" v-model="pageLength" :options="['10', '15', '20']" @input="(value) => props.perPageChanged({ currentPerPage: value })
                " />
            </div>
            <div>
              <b-pagination align="right" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })" :value="1" :per-page="pageLength"
                prev-class="prev-item" next-class="next-item" last-number :total-rows="props.total" first-number>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal title="Error" ok-only v-model="showErrorModal" centered ok-title="Accept" ok-variant="danger"
        modal-class="modal-danger">
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
      <b-modal :title="'Samplings de la orden #' + idOrden" ok-only v-model="showModal" centered ok-title="Entendido">
        <vue-good-table :rows="samplings" :columns="columnsSamplings" :sort-options="{
          enabled: false,
        }" :search-options="{
          enabled: false,
        }" :pagination-options="{
          enabled: false,
        }">
          <template slot="emptystate">No hay samplings para mostrar</template>

          <template slot="table-row" slot-scope="props">
            <!-- Column: Nombre -->
            <span v-if="props.column.field === 'nombre'">
              <p class="mb-0">
                {{ props.row.nombre === "" ? "--" : props.row.nombre }}
              </p>
            </span>
            <!-- Column: Descripción -->
            <span v-else-if="props.column.field === 'descripcion'">
              <p class="mb-0">
                {{ props.row.descripcion === "" ? "--" : props.row.descripcion }}
              </p>
            </span>
            <!-- Column: Código -->
            <span v-else-if="props.column.field === 'codigo'">
              <p class="mb-0">
                {{ props.row.codigo === "" ? "--" : props.row.codigo }}
              </p>
            </span>
            <!-- Column: Otras Columnas -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BButton,
  BAvatar,
  BPopover,
  BSpinner,
  BDropdown,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ModalActivateShop from "../components/views-rol/ModalActivateShop.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  isPicker,
  isCajero,
  getUserID,
  isSuperUser,
  getRoleUser,
  isSupervisor,
  getAuthToken,
  isAdministrator,
} from "@/auth/utils";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BAvatar,
    vSelect,
    BButton,
    BPopover,
    BSpinner,
    BDropdown,
    BCardText,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    ModalActivateShop
  },
  data() {
    return {
      page: 1,
      rows: [],
      error: "",
      stores: [],
      bodegas: [],
      clientes: [],
      unwatch: null,
      loading: true,
      searchTerm: "",
      pageLength: 20,
      totalRecords: 0,
      seachTimeout: null,
      seachTimeout2: null,
      storeSelected: 0,
      loadingStores: true,
      bodegaSelected: 0,
      loadingBodegas: true,
      clienteSelected: 0,
      loadingClientes: false,
      inputClient: "",
      showErrorModal: false,
      showStoreSelect: false,
      loadingActivate: false,
      warehouseSelected: null,
      showActivateButton: false,
      showWarehouseSelect: false,
      filteredRows: [],
      allStoresKey: 9999999,
      samplings: [],
      showModal: false,
      idOrden: 0,
      columns: [
        {
          label: "Origen",
          field: "origen",
        },
        {
          label: "Fecha / Hora",
          field: "fechacreacion",
        },
        {
          label: "Numero",
          field: "idorden",
        },
        {
          label: "Cliente",
          field: "nombrecliente",
        },
        {
          label: "Local",
          field: "lineadenegocio",
        },
        {
          label: "Samplings",
          field: "samplings",
        },
        {
          label: "Picking",
          field: "picker",
        },
        {
          label: "Entrega",
          field: "tipoentrega",
        },
        {
          label: "Estado",
          field: "estadoorden",
        },
        {
          label: "Motorizado",
          field: "motorizado",
        },
      ],
      columnsSamplings: [
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Descripción",
          field: "descripcion",
        },
        {
          label: "Código",
          field: "codigo",
        },
      ]
    };
  },
  watch: {
    clienteSelected: function (newValue) {
      if (newValue == null) {
        this.clienteSelected = 0;
        this.onChangeFilters();
      }
      if (newValue == "") {
        this.clienteSelected = 0;
        this.clientes = [];
      }
    },
    bodegaSelected: function (newValue) {
      if (newValue == null) {
        this.bodegaSelected = 0;
        this.onChangeFilters();
      }
    },
    storeSelected: function (newValue) {
      if (newValue == null) {
        this.storeSelected = this.allStoresKey;
        this.onChangeFilters();
      }
    },
  },
  beforeMount() {
    if (["AD", "GE", "SG", "CA"].includes(getRoleUser())) {
      this.columns = this.columns.filter(c => c.field !== "samplings" && c.field !== "picker");
    }
  },
  mounted() {
    const inputClient = this.$refs.clienteSelect.$el.querySelector("input");
    inputClient.addEventListener("input", this.onInputClient);
    const promises = [];
    switch (getRoleUser()) {
      case "SA":
        promises.push(this.getStoresAllowed());
        promises.push(this.getWarehousesAllowed());
        break;
      case "AD":
      case "GE":
        promises.push(this.getStoresAllowed());
        break;
      case "SU":
        promises.push(this.getWarehousesAllowed());
        break;
      case "PK":
        promises.push(this.getWarehousesAllowed());
        break;
      default:
        promises.push(this.getStoresAllowed());
        break;
    }
    Promise.all(promises)
      .then(() => {
        this.getOrders().then(() => {
          this.listenSocketOrders();
        });
      })
      .catch((error) => {
        this.error = error;
      });
    if (this.isAdministrator) {
      this.onStoreSelectedChange();
    }
  },
  beforeDestroy() {
    this.unwatchStore && this.unwatchStore();
    this.unwatchSocketOrder && this.unwatchSocketOrder();
  },
  computed: {
    isCajero,
    isPicker,
    isSuperUser,
    isSupervisor,
    isAdministrator,
    entregaText() {
      const status = {
        IM: "Inmediata",
        PG: "Programada",
        SA: "Sin Apuros",
      };
      return (s) => status[s];
    },
    entregaVariant() {
      const statusColor = {
        PG: "light-info",
        SA: "light-warning",
        IM: "light-success",
      };
      return (s) => statusColor[s];
    },
    statusText() {
      const status = {
        PG: "Pendiente",
        CR: "Creada",
        AN: "Anulada",
        PR: "Preparada",
        EN: "Enviada",
        EP: "En preparación",
        LL: "LLego",
        NE: "No Entregada",
        ET: "Entregada",
      };
      return (s) => status[s];
    },
    statusVariant() {
      const statusColor = {
        CR: "light-warning",
        PG: "light-warning",
        AN: "light-danger",
        PR: "light-info",
        EN: "light-info",
        EP: "light-info",
        LL: "light-info",
        NE: "light-danger",
        ET: "light-success",
        SE1: "light-secondary",
        SE2: "light-success",
      };
      return (s) => statusColor[s];
    },
  },
  methods: {
    onStatusStore(data) {
      this.storeData = data;
      if (this.storeSelected !== this.allStoresKey) {
        if (this.storeData && this.storeData.activo == 0) {
          this.showActivateButton = true;
          return;
        }
      }
      this.showActivateButton = false;
    },
    onActivatedStore() {
      this.showActivateButton = false;
    },
    onChangeFilters() {
      this.getOrders();
    },
    onSearch(query) {
      this.searchTerm = query;
      this.filteredRows = this.rows.filter((row) =>
        row.idorden.toString().includes(this.searchTerm)
      );
      if (this.filteredRows.length == 0 || this.searchTerm === "") {
        this.loading = true;
        clearTimeout(this.seachTimeout);
        this.seachTimeout = setTimeout(() => {
          this.getOrders();
        }, 2500);
      }
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.getOrders();
    },
    onPerPageChange(params) {
      this.pageLength = params.currentPerPage;
      this.getOrders();
    },
    onSortChange(params) {
      this.sort = {
        type: params.sortType,
        field: this.columns[params.columnIndex].field,
      };
      this.getOrders();
    },
    onNewOrder() {
      this.getOrders();
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("hh:mm a");
    },
    onOrderClick(params) {
      this.$router.push({
        name: "orden-detalle",
        params: { id: params.row.idorden, orden: params.row },
      });
    },
    getWarehousesAllowed() {
      this.showStoreSelect = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/UserBodega",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            this.loadingBodegas = false;
            this.loadingStores = false;
            if (res.data.bOk) {
              if (isSuperUser()) {
                res.data.lsBodegas.unshift({ id: 0, nombre: "Todas" });
              }
              this.bodegas = res.data.lsBodegas;
              if (isSuperUser()) {
                this.bodegaSelected = 0; //TODAS
              } else {
                const bodegaSelected = res.data.lsBodegas.find(
                  (s) => s.seleccionada
                );
                if (bodegaSelected) {
                  this.bodegaSelected = bodegaSelected.id;
                } else {
                  if (res.data.lsBodegas.length > 0)
                    this.bodegaSelected = res.data.lsBodegas[0];
                  else reject("No hay bodegas disponibles");
                }
              }
              resolve();
            } else {
              reject(res.data.mensaje);
              this.loadingBodegas = false;
              this.loadingStores = false;
            }
          })
          .catch((e) => {
            this.loadingBodegas = false;
            this.loadingStores = false;
            reject(e.message);
          });
      });
    },
    getStoresAllowed() {
      this.showWarehouseSelect = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/UserStores",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            this.loadingStores = false;
            if (res.data.bOk) {
              this.stores = res.data.lsTiendas;
              if (res.data.lsTiendas.length === 1) {
                this.storeSelected = res.data.lsTiendas[0].id;
              } else {
                this.storeSelected = this.allStoresKey;
              }
              res.data.lsTiendas.unshift({ id: this.allStoresKey, nombre: "Todas" });
              resolve();
            } else {
              reject(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.loadingStores = false;
            reject(e.message);
          });
      });
    },
    getOrders() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/OrdenesCallCenterGeneral",
            body: JSON.stringify({
              IDEmpresaAsociada: this.storeSelected === this.allStoresKey ? 0 : this.storeSelected,
              IDBodega: this.bodegaSelected ?? 0,
              IDCliente: this.clienteSelected ?? 0,
              IDOrden: this.searchTerm == "" ? 0 : this.searchTerm,
              Filas: this.pageLength,
              Pagina: this.page,
              Estado: "",
              TipoOrden: "",
              TipoCliente: "",
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            this.loading = false;
            if (res.data.bOk) {
              this.rows = res.data.lstOrdenes;
              this.totalRecords = res.data.totalregistros;
              resolve();
            } else {
              this.error = res.data.mensaje;
              this.showErrorModal = true;
              reject();
            }
          })
          .catch((e) => {
            this.loading = false;
            this.error = e.message;
            this.showErrorModal = true;
            reject();
          });
      });
    },
    listenSocketOrders() {
      this.unwatchSocketOrder = this.$store.watch(
        (_, g) => {
          return g["app/socketOrder"];
        },
        (_) => {
          this.onNewOrder();
        }
      );
    },
    onStoreSelectedChange() {
      this.unwatchStore = this.$store.watch(
        (_, g) => {
          return g["app/storeSelected"];
        },
        (storeID, __) => {
          if (storeID !== undefined && storeID !== null) {
            this.storeSelected = storeID;
            this.onChangeFilters();
          }
        }
      );
    },
    getClients() {
      this.loadingClientes = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/GetClientes",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
              BusquedaCliente: this.inputClient,
            }),
          })
          .then((res) => {
            this.loadingClientes = false;
            if (res.data.bOk) {
              res.data.lsClientes.unshift({ id: 0, nombre: "Todos" });
              this.clientes = res.data.lsClientes;
              this.clienteSelected = 0; //TODAS
              this.inputClient = "";
              resolve();
            } else {
              reject(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.loadingClientes = false;
            reject(e.message);
          });
      });
    },
    onInputClient(event) {
      const text = event.target.value;
      this.inputClient = text;
      clearTimeout(this.seachTimeout2);
      this.seachTimeout2 = setTimeout(() => {
        this.getClients();
      }, 1000);
    },
    onSamplingsClick(samplings, idOrden) {
      this.samplings = samplings;
      this.idOrden = idOrden;
      this.showModal = true;
    },
    showMsgEstado() {
      this.$bvModal
        .msgBoxConfirm("Esta seguro que desea Activar la Tienda?", {
          size: "sm",
          title: "Activar Tienda",
          okTitle: "Activar",
          centered: true,
          okVariant: "primary",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.loadingActivate = true;
            this.$http
              .post(this.$store.state.app.middlewareURL, {
                path: "/Administracion/AdminEmpresaAsociada",
                body: JSON.stringify({
                  opt: "ADT",
                  empresa: 2,
                  usuario: `${getUserID()}`,
                  idAsociada: this.storeData.IDEmpresaAsociado ?? this.storeData.idEmpresaAsociado,
                  tokenSesion: getAuthToken(),
                }),
              })
              .then((res) => {
                if (res.data.bOk) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Éxito",
                      icon: "CheckIcon",
                      text: "Se cambio correctamente el estado de la tienda.",
                      variant: "success",
                    },
                  });
                  this.storeData.activo = 1;
                  this.showActivateButton = false;
                } else {
                  throw new Error(res.data.mensaje);
                }
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "AlertTriangleIcon",
                    text: `Ocurrió un error al cambiar el estado de la tienda (${e.message}).`,
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.loadingActivate = false;
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
table.vgt-table {
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
